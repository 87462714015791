<template>
  <div>
    <div id="payslip">
      <div id="scope1">
        <div>
          <div>
            <img class="logo" :src="Entete.logo_url" />
            <!-- <img class="logo" src="~/assets/logo-picto.png" /> -->
          </div>
          <!-- <div class="headertitle">{{ Entete.name }}</div> -->
        </div>
        <div>{{ date }}</div>
      </div>

      <div id="scope1">
        <div></div>
        <div class="">
          <div class="title mb-5"><h2>À</h2></div>
          <div class="value">{{ toPrint.user_name }}</div>
          <div class="value">Téléphone : {{ toPrint.user_phone }}</div>
          <div class="value">Poste : {{ toPrint.user_poste }}</div>
          <div class="value">Date d'embauche : {{ toPrint.hiring_date }}</div>
        </div>
      </div>
      <div id="scope">
        <div class="scope-entry">
          <div class="title2">Notification de congé</div>
        </div>
      </div>

      <br /><br /><br />

      <div class="">
        <div class="nti">
          <p class="entry">
            MOTIF :
            <span class="bold title1">{{ toPrint.motive }}</span> .
          </p>
          <br />
          <p>
            Ce congé prend effet à partir du
            <span class="bold"> {{ toPrint.from }}</span> et s'étend sur
            <span class="bold"> {{ toPrint.duration }} jours.</span> Nous vous
            prions de respecter la période définie, et espérons que ce congé ou
            repos influencera positivement votre productivité dès votre retour.
          </p>
          <!-- <p>Bien à vous !</p> -->
        </div>
      </div>

      <div id="scope2">
        <br /><br />
        <div>LE RESPONSABLE</div>
      </div>

      <div class="footer">
        <div
          class="text-right sig"
          style="bottom: 0; font-size: 10px; opacity: 0.5"
        >
          Edité par FlashCar
        </div>
        <hr />
        <div class="footercontent">
          {{ Entete.telephone }} - RCCM {{ Entete.rccm }} - IFU
          {{ Entete.ifu }}
        </div>
        <div class="footercontent">
          {{ Entete.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FichePaie",

  data: () => ({
    toPrint: {},
    Entete: {},
  }),

  computed: {
    date() {
      var today = new Date();
      let annee = today.getFullYear();
      let mois = ("0" + (today.getMonth() + 1)).slice(-2);
      let jour = ("0" + today.getDate()).slice(-2);
      var date = jour + "/" + mois + "/" + annee;

      return date;
    },
  },

  mounted() {
    let data = this.$router.currentRoute.query;
    this.toPrint = data;

    this.getEntete().then(() => {
      setTimeout(() => {
        window.print();
      }, 2000);
    });
  },

  methods: {
    telecharger() {
      var printContents = document.getElementById("printDiv").innerHTML;
      var originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      window.print();
      window.onafterprint = window.close();
    },

    async getEntete() {
      await this.$http.get("/societes").then((response) => {
        this.Entete = response.data.data[0]
        if (!this.Entete) this.Entete = {};
      });
    },

    toDate(dateStr) {
      if (dateStr) {
        dateStr = dateStr.replace(/\//g, "-");
        var parts = dateStr.split("-");
        return this.datetoString(parts[1]) + " " + parts[0];
      }
    },

    datetoString(number) {
      // let numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
      let mois = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      return mois[parseInt(number - 1)];
    },

    reformatDate(date) {
      if (date) {
        let annee = date.getFullYear();
        let mois = date.getMonth();
        let jour = date.getDate();
        date = annee + "-" + mois + "-" + jour;
        return date.toString();
      }
      return Date.parse(date);
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  background: #f0f0f0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 100%;
}

.logo {
  // width: 150px !important;
  width: 40%;
  max-width: 150px;
  height: auto;
}

.headertitle {
  text-align: center !important;
}

#payslip {
  background: #fff;
  padding: 30px 40px;
}

.footer {
  margin-top: 390px !important;
}

.footercontent {
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: bold;
  font-style: italic;
}

#title {
  margin-bottom: 20px;
  font-size: 48px;
  font-weight: 600;
}

#title1 {
  // margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 600;
}

#scope {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-around;

  margin-top: 60px;
}

.sig {
  text-align: right !important;
}

#scope1 {
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;
}

#scope2 {
  padding: 7px 0 4px 0;
  display: flex;
  justify-content: space-between;

  margin-top: 35px;
}

#scope > .scope-entry {
  text-align: center;
}

#scope1 > .scope-entry {
  // text-align: center;
}

#scope > .scope-entry > .value {
  font-size: 14px;
  font-weight: 700;
}

.content {
  // display: flex;
  border-bottom: 1px solid #ccc;
  // height: 880px;
  //   text-transform: uppercase;
}

.details .entry {
  display: flex;
  justify-content: space-between;
  // margin-bottom: 6px;
}

.details .entry .value {
  font-weight: 700;
  max-width: 130px;
  text-align: right;
}

.ytd .title {
  font-size: 12px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  margin-bottom: 6px;
}

.title1 {
  font-size: 15px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  padding-bottom: 4px;
  margin-bottom: 6px;
  text-transform: uppercase;
}

.title2 {
  font-size: 20px;
  font-weight: 700;
  // border-bottom: 1px solid #ccc;
  // padding-bottom: 4px;
  // margin-bottom: 6px;
  text-transform: uppercase;
}

.nti div {
  font-weight: 600;
  font-size: 15px;
}

.nti {
  padding: 3px 0 2px 0;
  margin-bottom: 10px;
  // background: rgba(0, 0, 0, 0.04);
  text-align: justify;
}
</style>
